<!-- eslint-disable -->
<template>
  <div class="page_box">
    <sb-headline :title="'平台裂变活动'"></sb-headline>
    <my-table ref="list" :data="listData" @page="pageHandler" :listLoading="listLoading">
      <!-- 表格数据搜索 -->
      <template #header="params">
        <div class="search_box">
          <el-form :inline="true" :model="searchData" label-position="left">
            <el-form-item label="活动编码">
              <el-input v-model="searchData.activityCode" placeholder="活动编码" clearable></el-input>
            </el-form-item>
            <el-form-item label="活动名称">
              <el-input v-model="searchData.activityName" placeholder="活动名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="活动状态">

              <el-select v-model="searchData.state">
                <el-option label="草稿" value="0"></el-option>
                <el-option label="未开始" value="1"></el-option>
                <el-option label="进行中" value="2"></el-option>
                <el-option label="已结束" value="3"></el-option>

              </el-select>
            </el-form-item>
            <el-form-item label="前端是否展示">

              <el-select v-model="searchData.isShow">
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-form>

          <el-row :gutter="10">
            <el-col :span="4" :offset="20">
              <el-button @click="resetSearchData">重置</el-button>
              <el-button @click="pageHandler(params, 1)" type="primary">查询</el-button>
            </el-col>
          </el-row>
        </div>
      </template>
      <template #navBar>
        <div class="table_nav_bar">
          <router-link to="/management/fission/fission-campagin-add">
            <el-button type="primary">新建活动</el-button>
          </router-link>
        </div>
      </template>
      <el-table-column label="活动名称" fixed="left" prop="activityName" :min-width="180">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="gotoDetail(scope.row)">{{ scope.row.activityName }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="活动ID" prop="activityCode" :min-width="160"></el-table-column>
      <el-table-column label="活动时间" prop="" :min-width="300">
        <template slot-scope="scope">
          <div>{{ scope.row.startTime }} - {{ scope.row.endTime }}</div>
        </template>
      </el-table-column>
      <el-table-column label="前端是否展示" prop="" :min-width="100">
        <template slot-scope="scope">
          <el-switch
            @change="(e)=>changeState(e,scope.row)"
            v-model="scope.row.isShow"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="浏览PV" prop="browsePvCount" :min-width="100"></el-table-column>
      <el-table-column label="浏览UV" prop="browseUvCount" :min-width="100"></el-table-column>
      <el-table-column label="分享PV" prop="sharePvCount" :min-width="100"></el-table-column>
      <el-table-column label="分享UV" prop="shareUvCount" :min-width="100"></el-table-column>
      <el-table-column label="购买单数" prop="purchaseCount" :min-width="100"></el-table-column>
      <el-table-column label="购买人数" prop="purchaseUserCount" :min-width="100"></el-table-column>
      <el-table-column label="活动状态" prop="state" :min-width="120">
        <template slot-scope="scope">
          <div v-if="scope.row.state==1">未开始</div>
          <div v-if="scope.row.state==2">进行中</div>
          <div v-if="scope.row.state==3">已结束</div>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="createTime" :min-width="180"></el-table-column>
      <!-- <el-table-column label="banner图片" prop="" :min-width="230">
        <template slot-scope="scope">
          <el-image style="width: 60px; height: 60px" :src="scope.row.bannerImage" :preview-src-list="[scope.row.bannerImage]"></el-image>
        </template>
      </el-table-column> -->
      <el-table-column label="操作" fixed="right" prop="" width="200">
        <template slot-scope="scope">
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(1, scope.row)">增加渠道</el-button>
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(2, scope.row)">数据</el-button>
          <!-- <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(3, scope.row)">复制</el-button> -->
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(4, scope.row)">查看推广信息</el-button>
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(5, scope.row)">配置现金奖励</el-button>
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(9, scope.row)">配置卡券奖励</el-button>
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(6, scope.row)" v-if="scope.row.state == 1">发布</el-button>
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(7, scope.row)" v-if="scope.row.state == 2">结束</el-button>
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(8, scope.row)" v-if="scope.row.state == 3 || scope.row.state == 2">重新编辑</el-button>
        </template>
      </el-table-column>
    </my-table>
    <!-- 增加渠道弹窗 -->
    <el-dialog title="请输入渠道名称" :close-on-click-modal="false" :close-on-press-escape="false" :visible.sync="channelDialogVisible" center width="50%" :before-close="channelHandleClose">
      <div v-loading="creatCodeLoading" class="channel_item" v-for="(item, index) in channelNameList" :key="index">
        <el-input type="text" v-model="channelNameList[index].channel" placeholder="请输入渠道类型"></el-input>
        <div style="margin: 0 5px;">-</div>
        <el-input type="text" v-model="channelNameList[index].linkName" placeholder="请输入渠道名称"></el-input>
        <div style="margin: 0 5px;">-</div>
        <el-input type="text" v-model="channelNameList[index].channelMobile" placeholder="请输入渠手机号"></el-input>
        <el-button @click="channelNameFn('add',index)" type="text" v-if="(channelNameList.length-1) == index">增行</el-button>
        <el-button @click="channelNameFn('del',index)" type="text" v-else>删除</el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="channelHandleClose">取 消</el-button>
        <el-button type="primary" @click="channelHandleSubmit">生成二维码</el-button>
      </span>
    </el-dialog>
    <!-- 推广信息弹窗 -->
    <el-dialog title="推广信息" :close-on-click-modal="false" :close-on-press-escape="false" :visible.sync="promotionDialogVisible" center width="60%" :before-close="promotionHandleClose">
      <el-table :data="activityPopularizeList" border style="width: 100%" size="mini">
        <el-table-column fixed="left" prop="linkName" label="渠道名称" width="120" align="center"></el-table-column>
        <el-table-column prop="linkCode" label="推广编码" width="160" align="center"></el-table-column>
        <el-table-column prop="channel" label="渠道类型" width="120" align="center"></el-table-column>
        <el-table-column prop="channelMobile" label="渠道手机号" width="120" align="center"></el-table-column>

        <el-table-column prop="date" label="普通二维码" align="center">
          <template slot-scope="scope">
            <div class="qrcode_img_src" :id="scope.row.linkCode"></div>
            <el-image
              v-loading="!scope.row.qrcodeSrc"
              style="width: 100px; height: 100px"
              :src="scope.row.qrcodeSrc"
              :preview-src-list="[scope.row.qrcodeSrc]">
            </el-image>
            <div class="popularize_list_qrcode">
              <el-button type="text" size="mini" @click="handleDownloadQR(scope.row)">下载普通二维码</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="小程序二维码" align="center">
          <template slot-scope="scope">
            <div class="popularize_list_qrcode">
              <el-image
                style="width: 100px; height: 100px"
                :src="scope.row.miniQrUrl"
                :preview-src-list="[scope.row.miniQrUrl]">
              </el-image>
              <el-button type="text" size="mini" @click="downloadQrcodeWx(scope.row)">下载小程序二维码</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="copyLink(scope.row)">点击复制链接</el-button>
            <el-button type="text" size="mini" @click="handelPhone(scope.row)">{{ scope.row.channelMobile?'更换手机号':'绑定手机号' }}</el-button>
            <el-button type="text" size="mini" @click="handelPhone(scope.row,'delete')" v-if="scope.row.channelMobile">解绑手机号</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>


    <!-- 增加渠道弹窗 -->
    <el-dialog title="奖励配置" :close-on-click-modal="false" :close-on-press-escape="false" :visible.sync="rewardDialogVisible"  center width="70%" :before-close="rewardHandleClose">
      <el-table :data="commissionConfList" border style="width: 100%" size="mini">
        <el-table-column prop="skuName" label="商品名称" width="180">
        </el-table-column>

        <el-table-column prop="activityPrice" label="活动价格" >
        </el-table-column>

        <el-table-column  label="一级裂变奖励类型" >
          <template slot-scope="scope">
            <el-select v-model="scope.row.fissionCommission.firstLevelAttrType">
              <el-option :value="1" label="固定值"></el-option>
              <el-option :value="2" label="百分比"></el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column label="一级裂变数值" >
          <template slot-scope="scope">
            <el-input v-model="scope.row.fissionCommission.firstLevelRewardVal" @change="handleEdit(scope.$index, scope.row)"></el-input>
          </template>
        </el-table-column>

        <el-table-column label="二级裂变奖励类型" >
          <template slot-scope="scope">
            <el-select v-model="scope.row.fissionCommission.secondLevelAttrType">
              <el-option :value="1" label="固定值"></el-option>
              <el-option :value="2" label="百分比"></el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column label="二级裂变数值" >
          <template slot-scope="scope">
            <el-input v-model="scope.row.fissionCommission.secondLevelRewardVal" @change="handleEdit(scope.$index, scope.row)"></el-input>
          </template>
        </el-table-column>

        <el-table-column label="渠道一级奖励类型" >
          <template slot-scope="scope">
            <el-select v-model="scope.row.channelCommission.firstLevelAttrType">
              <el-option :value="1" label="固定值"></el-option>
              <el-option :value="2" label="百分比"></el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column label="一级渠道数值" >
          <template slot-scope="scope">
            <el-input v-model="scope.row.channelCommission.firstLevelRewardVal" @change="handleEdit(scope.$index, scope.row)"></el-input>
          </template>
        </el-table-column>

        <el-table-column  label="渠道二级奖励类型" >
          <template slot-scope="scope">
            <el-select v-model="scope.row.channelCommission.secondLevelAttrType">
              <el-option :value="1" label="固定值"></el-option>
              <el-option :value="2" label="百分比"></el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column label="二级渠道数值">
          <template slot-scope="scope">
            <el-input v-model="scope.row.channelCommission.secondLevelRewardVal" @change="handleEdit(scope.$index, scope.row)"></el-input>
          </template>
        </el-table-column>
      </el-table>

      <span slot="footer" class="dialog-footer">
        <el-button @click="rewardHandleClose">取 消</el-button>
        <el-button type="primary" @click="rewardHandleSubmit">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import { Table } from "@/utils/decorator";
import QRCode from "qrcodejs2";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
    data() {
    //这里存放数据
      return {
        listData: [{},{},{}],
        // 搜索数据
        searchData: {
          activityCode: "",
          activityName: "",
        },
        searchKey: "activityCode", //下拉input所选Key
        listLoading: false, //列表loading
        channelDialogVisible: false,           //增加渠道弹窗状态
        channelNameList: [                     //渠道名称list
          { channel: '', linkName: '' ,channelMobile: ''}
        ],
        promotionDialogVisible: false,        //推广信息弹窗
        rowActivityCode: "",                  //当前点击的活动code
        activityPopularizeList: [],           //活动推广列表
        url: "",
        rewardDialogVisible: false,
        commissionConfList: [],
        orderItems: [{
          "item":"createTime",
          "type":"desc"
        }],
        creatCodeLoading: false,
      };
    },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    pageHandler(params, num) {
      this.getActivityList(params, num ? num : params.pageNum, params.pageSize);
    },
    // 推广信息表格操作
    handelPhone(row,type){
      if(type == 'delete'){
        this.$confirm(`是否解绑此渠道手机号【${row.channelMobile}】?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.dispatch('marketing/activityBindMobile',{
            linkCode: row.linkCode,
            channelMobile: ''
          }).then(res => {
            if(res.code == 0){
              this.$message({
                type: 'success',
                message: '解绑成功!'
              });
              this.getActivityPopularizeList();
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消解绑'
          });
        });
      }else{
        this.$prompt('请输入手机号', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputValue: row.channelMobile,
          inputValidator: (val) => { if(!val) return '请输入手机号' }
        }).then(({ value }) => {
          this.$store.dispatch('marketing/activityBindMobile',{
            linkCode: row.linkCode,
            channelMobile: value
          }).then(res => {
            if(res.code == 0){
              this.$message({
                type: 'success',
                message: '绑定成功'
              });
              this.getActivityPopularizeList();
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });       
        });
      }

    },
    // 前端是否展示change更改状态
    changeState(e,row){
      this.$store.dispatch('management/postChangeShowStatus',{
        activityCode: row.activityCode,
        isShow: e,
      }).then(res => {
        this.$refs.list.init();
      })
    },
    // 表格内操作
    tableHandle(type, row) {
      this.rowActivityCode = row.activityCode;
      switch (type) {
        case 1:     //增加渠道
          this.channelNameList = [{ channel: '', linkName: '' }];
          this.channelDialogVisible = true;
          break;
        case 2:     //数据
        console.log(row)
          this.$router.push(`/management/fission/fission-analysis-page/${row.activityCode}?activityName=${row.activityName}`)
          break;
        case 3:     //复制

          break;
        case 4:     //查看推广信息
          // 获取推广信息列表
          this.getActivityPopularizeList();
          this.promotionDialogVisible = true;
          break;
        case 5:
          this.getActivityCommissionConf();
          this.rewardDialogVisible = true;
          break;
          case 6:
            this.publishActivity()
            window.location.reload();
            break
          case 7:
            this.closeActivity()
            window.location.reload();
            break;
          case 8:
            this.returnDraftActivity();
            window.location.reload();
            break
          case 9:
          this.$router.push(`/management/fission/fission-share-award?activityCode=${row.activityCode}&activityName=${row.activityName}`)
            break
        default:
          break;
      }
    },
    // 跳转活动详情
    gotoDetail(row){
      this.$router.push(`/management/fission/fission-campagin-add/${row.activityCode}`)
    },
    publishActivity(){
      this.$store.dispatch('marketing/publishActivity',{
        activityCode: this.rowActivityCode,
      }).then(res => {
        if(res.code == 0){
          this.$message.success('发布操作成功')
        }else{
          this.$message.error(res.msg)
        }
      })
    },

    closeActivity(){
      this.$store.dispatch('marketing/closeActivity',{
        activityCode: this.rowActivityCode,
      }).then(res => {
        if(res.code == 0){
          this.$message.success('活动结束操作成功')
        }else{
          this.$message.error(res.msg)
        }
      })
    },

    returnDraftActivity(){
      this.$store.dispatch('marketing/returnDraftActivity',{
        activityCode: this.rowActivityCode,
      }).then(res => {
        if(res.code == 0){
          this.$message.success('下线至草稿操作成功')
        }else{
          this.$message.error(res.msg)
        }
      })
    },

    getActivityCommissionConf(){
      this.$store.dispatch('marketing/getCommissionConf',{
        activityCode: this.rowActivityCode,
      }).then(res => {
        if(res.code == 0){
          this.commissionConfList = res.data;
        }
      })
    },
    // 添加渠道弹窗关闭
    channelHandleClose(){
      console.log('添加渠道弹窗关闭')
      this.channelNameList = [{ channel: '', linkName: '' }];
      this.channelDialogVisible = false;
    },
    // 添加渠道生成二维码
    channelHandleSubmit(){
      let isNext = this.channelNameList.some(item => {
        return item.channel == '' || item.linkName == '';
      })
      if(isNext) return this.$message.error('请输入完整的渠道名称！');

      // 防重复点击
      if(this.nextStep) return
      this.nextStep = true;
      this.creatCodeLoading = true;

      this.$store.dispatch('marketing/newActivityPopularizeCreate',{
        activityCode: this.rowActivityCode,
        linkList: this.channelNameList
      }).then(res => {
        if(res.code == 0){
          // 获取推广信息列表
          this.getActivityPopularizeList()
          this.$message.success('添加成功')
          this.channelDialogVisible = false;
          this.promotionDialogVisible = true;
        }else{
          this.$message.error('创建失败，请稍后重试！')
        }
        this.nextStep = false;
        this.creatCodeLoading = false;
      }).catch(err => {
        console.log(err)
        this.nextStep = false;
        this.creatCodeLoading = false;
      })
    },
    // 添加\删除渠道
    channelNameFn(type,index){
      if(type == 'add'){
        this.channelNameList.push({ channel: '', linkName: '' })
      }
      if(type == 'del'){
        this.$confirm('是否删除此渠道?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.channelNameList.splice(index,1);
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    },
    // 推广信息弹窗关闭
    promotionHandleClose(){
      this.promotionDialogVisible = false;
    },

    rewardHandleClose(){
      this.rewardDialogVisible = false;
    },

    // 重置搜索数据
    resetSearchData(e) {
      this.searchData = {
        activityCode: "",
        activityName: "",
      }
    },

    rewardHandleSubmit(){
      this.$store.dispatch('marketing/saveCommissionConf',{
        commissionConfItems: this.commissionConfList
      }).then(res => {
        if(res.code == 0){
          // 获取推广信息列表
         this.getActivityCommissionConf()
          this.$message.success('提交成功')
          this.rewardDialogVisible = false;
        }else{
          this.$message.error('提交失败，请稍后重试！')
        }
      })
    },

    // 复制链接
    copyLink(row){
      navigator.clipboard.writeText(row.linkUrl)
      .then(() => {
        this.$message.success('复制成功')
      })
      .catch(() => {
        this.$message.error('复制失败')
      })
    },
    qrCode(row,index) {
      document.getElementById(row.linkCode).innerHTML = "";
      let qrcode = new QRCode(row.linkCode, {
        width: 260, //图像宽度
        height: 260, //图像高度
        colorDark: "#000000", //前景色
        colorLight: "#ffffff", //背景色
        typeNumber: 4,
        correctLevel: QRCode.CorrectLevel.H //容错级别 容错级别有：（1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
      });
      qrcode.clear(); //清除二维码
      qrcode.makeCode(row.linkUrl); //生成另一个新的二维码
      setTimeout(() => {
        let arr = JSON.parse(JSON.stringify(this.activityPopularizeList))
        var imgs = document.getElementById(row.linkCode).getElementsByTagName("img");
        arr[index].qrcodeSrc = imgs[0]['src'];
        this.activityPopularizeList = arr;
      }, 200);
    },
    handleDownloadQR(row) {
      var a = document.createElement("a");
      a.download = "QR" + new Date().getTime() || '普通二维码';
      a.href = row.qrcodeSrc;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    downloadQrcodeWx(row){
      var a = document.createElement("a");
      a.download = "QR" + new Date().getTime() || '小程序';
      a.href = row.miniQrUrl;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    // 获取活动列表
    getActivityList(params,pageNum,pageSize) {
      this.listLoading = true;
      this.$store.dispatch('marketing/newActivityList',{
        pageNum,
        pageSize,
        orderItems: this.orderItems,
        ...this.searchData
      }).then(res => {
        if(res.code == 0){
          this.listData = res.data.list;
          params.render(res.data.total);
        }
        this.listLoading = false;
      })
    },
    // 获取推广信息列表
    getActivityPopularizeList(){
      this.$store.dispatch('marketing/newActivityPopularizeList',{
        activityCode: this.rowActivityCode,
        pageNum: 1,
        pageSize: 999
      }).then(res => {
        if(res.code == 0){
          this.activityPopularizeList = res.data.list;

          setTimeout(() => {
            this.activityPopularizeList.map((item,index) => {
              this.qrCode(item,index)
            })
          }, 2000);
        }
      })
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box {
    padding: 0 20px;
    .search_box {
      background: #fff;
      padding: 20px;
      margin-top: 20px;
    }
    .table_nav_bar{
      width: 100%;
      background: #fff;
      padding: 20px 20px 0 20px;
      box-sizing: border-box;
    }
  }
  .handle_btn{
    padding: 0;
  }
  .channel_item{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    >.el-input:nth-child(1){
      width: 260px;
    }
  }
  .channel_item:last-child{
    margin: 0;
  }
  .popularize_list_qrcode{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .qrcode_img_src{
    position: absolute;
    top: -10000%;
    z-index: -10000;
  }
  ::v-deep .el-table__header thead tr th:first-of-type, .el-table__body tbody tr td:first-of-type{
    padding: 0 !important;
  }
  ::v-deep .el-table__body tbody tr td:first-of-type {
    padding-left: 0px !important;
  }
</style>
